import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const ABOUT_US_LOCALES = {
	WOLTAIR_CZ: 'o-nas',
	WOLTAIR_PL: 'o-nas',
	WOLTAIR_DE: 'uber-uns',
	WOLTAIR_IT: 'chi-siamo'
}

export const ABOUT_US = ABOUT_US_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return ABOUT_US === param
}) satisfies ParamMatcher
